const navigationConstants = {
  HOME: "/",
  LOGIN: "/login",
  BRAND_CONFIG_TYPE: "/brand",
  FLAX_CONFIG_TYPE: "/flax",
  FLAX_CONFIG: "flax",
  BRAND_CONFIG: "brand",
  TAG_CONFIG: "/tag-config",
  get TAGS_BY_CONFIG() {
    return `${this.TAG_CONFIG}/:tagConfigType/:tagConfig`; // ../brand/brandName or ../flax/flaxId
  },
  TASKS: "/tasks",
  get TASKS_BY_CONFIG() {
    return `${this.TASKS}/:tagConfigType/:tagConfig`; // ../brand/brandName or ../flax/flaxId
  },
  SUBTASKS: "/task-details",
  get SUBTASKS_BY_TASKID() {
    return `${this.SUBTASKS}/:tagConfigType/:tagConfig/:taskId`;
  },
};

export default navigationConstants;
