import axios from "axios";
import { store } from "../reduxStore";
import generateJWTToken from "../modules/auth/services/generateJWTToken";

const authToken = store.getState()?.auth?.authToken;

let axiosCSPrivateInstance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
    Accept: "application/json, text/plain,",
  },
});

axiosCSPrivateInstance.interceptors.request.use(
  (config) => {
    if (
      !config.headers["Authorization"] ||
      config.headers["Authorization"] === "Bearer "
    ) {
      const AUTH_TOKEN = store.getState()?.auth?.authToken;
      config.headers["Authorization"] = `Bearer ${AUTH_TOKEN}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosCSPrivateInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    //if user is not authorized, redirect to login page
    console.log("error?.response", error?.response);
    console.log("error?.response?.status", error?.response?.status);
    if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;
      let newJWTToken = await generateJWTToken();
      console.log("newJWTToken", newJWTToken);
      if (!newJWTToken) {
        return Promise.reject(error);
      }
      originalConfig.headers["Authorization"] = `Bearer ${newJWTToken}`;
      if (newJWTToken) {
        axiosCSPrivateInstance.defaults.headers.common["Authorization"] =
          newJWTToken;
        return axiosCSPrivateInstance(originalConfig);
      } else {
        return;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosCSPrivateInstance;
