import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getCurrentUser from "../modules/auth/services/getCurrentUser";
import { useNavigate } from "react-router-dom";
import navigationConstants from "../routes/navigationConstants";

const useAuth = () => {
  const { authToken } = useSelector((state) => state.auth);
  const [isValidAuthToken, setIsValidAuthToken] = useState(false);
  const navigate = useNavigate();

  const checkIfTokenExpired = () => {
    getCurrentUser({
      onSuccess: () => {
        console.log("User is authenticated");
        setIsValidAuthToken(true);
      },
      onError: () => {
        console.log("User is not authenticated");
        setIsValidAuthToken(false);
        navigate(navigationConstants.LOGIN, { replace: true });
      },
    });
  };

  useEffect(() => {
    if (!authToken) {
      setIsValidAuthToken(false);
      navigate(navigationConstants.LOGIN, { replace: true });
    } else {
      checkIfTokenExpired();
    }
  }, [authToken]);

  return { isValidAuthToken, setIsValidAuthToken };
};

export default useAuth;
