import FallBackPage from "../../pages/fallBackPage/FallBackPage";
import { Suspense } from "react";
import useAuth from "../../../customHooks/useAuth";

const ProtectedRoutes = ({ children }) => {
  const { isValidAuthToken } = useAuth();
  console.log("isValidAuthToken", isValidAuthToken);

  if (isValidAuthToken) {
    return <Suspense fallback={<FallBackPage />}>{children}</Suspense>;
  } else {
    return <FallBackPage />;
  }
};
export default ProtectedRoutes;
